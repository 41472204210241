import request from "@/utils/request";
import requestOther from "@/utils/requestOther";

const commonParam = {
    timestamp: new Date().getTime(),
    nonce: "abc",
};
//用户登录
export function fetchUserLogin(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/login", { ...param });
}
//新增编辑菜单
export function fetchAddMenu(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/upsertV3SysMenu", { ...param });
}
//查询所有菜单无选中状态 多个页面可通用
export function fetchSearchMenu(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/queryMenuTreeList", { ...param });
}
//删除菜单
export function fetchDeleteMenu(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/delV3SysMenu", { ...param });
}
//部门树形结构   部门管理和角色管理页面使用
export function fetchDepartmentList() {
    const param = { ...commonParam };
    return request.post("admin/queryDeptTreeList", { ...param });
}
//根据账号查询菜单
export function fetchAccountMenuTreeList() {
    const param = { ...commonParam };
    return request.post("admin/queryAccountMenuTreeList", { ...param });
}
//所有枚举查询listEnumData
export function fetchListEnumData() {
    const param = { ...commonParam };
    return request.post("data/listEnumData", { ...param });
}
//区域树结构
// export function Upload(data) {
//     return request({ url: '/ktdb/projectAdmission/import', method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data })

// }
//区域树结构

export function fetchSysAreaList(data) {
    const param = { ...commonParam, ...data };
    return request.post("data/querySysAreaList", { ...param });
}
//门店业态列表
export function fetchShopBusinessFormat(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/listScEcomShopBusinessFormat", { ...param });
}
//平台商品分类树结构
export function fetchGoodsCateTreeList(data) {
    const param = { ...data, ...commonParam };
    return requestOther.post("wmsAdmin/queryGoodsCateTreeList", { cate_type: 1, ...param });
}

//查询平台商品库
export function fetchPlatformProduct(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/listScEcomPluApp", { ...param });
}
//门店列表
export function fetchShopList(data) {
    const param = { ...commonParam, ...data };
    return request.post("admin/listScFrameUserShop", { ...param });
}
//优惠券列表
export function fetchListCoupon(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/listCoupon", { ...param });
}
//刷新token
export function fetchRefreshToken(data) {
    const param = { ...data, ...commonParam };
    return request.post("data/refreshToken", { ...param });
}
//检查商品是否重复
export function fetchCheckRepeat(data) {
    const param = { ...data, ...commonParam };
    return request.post("admin/checkPluInOtherCoupon", { ...param });
}
//货币列表
export function fetchPtcodeList(data) {
    const param = { ...data, ...commonParam };
    return request.post("sys/listEcomPaytype", { ...param });
}
export function fetchApiExport(data, expportUrl, fileName) {
    const param = { ...data, ...commonParam };
    request.post(expportUrl, { ...param }, { responseType: "blob" }).then((res) => {
        // let fileName = decodeURI((res.headers['Content-Disposition'].split("="))[1])
        // console.log('fileName: ', fileName);
        const blob = new Blob([res]); //返回一个新的blob对象
        // let fileName = ""; //下载文件名
        const downLoadElement = document.createElement("a"); //创建a标签
        downLoadElement.style.display = "none"; //a标签样式为隐藏
        const href = window.URL.createObjectURL(blob); //创建window临时地址
        downLoadElement.href = href; //跳转地址
        downLoadElement.setAttribute("download", fileName);
        document.body.appendChild(downLoadElement); //将指定的dom添加的body之后
        downLoadElement.click(); //点击事件
        document.body.removeChild(downLoadElement); //移除dom
        window.URL.revokeObjectURL(href); //释放url地址
    });
}
