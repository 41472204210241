import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import { showLoading, closeLoading } from '@/utils/loading'
import { resetTokenAndClearUser } from '@/utils'

const service = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "/dataView" : "/data/api",
    // timeout: 600000,
})

service.interceptors.request.use(config => {
    showLoading()
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = token,
            config.headers.device = 2,
            config.headers.version = '3.0'
    }

    return config
}, (error) => Promise.reject(error))

service.interceptors.response.use(response => {
    closeLoading()
    const res = response.data
    // 这里是接口处理的一个示范，可以根据自己的项目需求更改
    // 错误处理
    if (res.code != 200 && res.message) {
        Message.error(res.message
        )
        // token 无效，清空路由，退出登录
        if (res.code == 1004) {
            resetTokenAndClearUser()
            router.push('login')
        }
        return Promise.reject()
    }
    // 如果接口正常，直接返回数据
    return res
}, (error) => {
    closeLoading()
    if (error.name == 'Error') {
        Message.error(error.message
        )
    } else {
        Message.error(error.response.data.data || error.message
        )
    }

    return Promise.reject(error)
})

export default service
