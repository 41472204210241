<template>
    <el-dropdown ref="dropdownSearch" trigger="click" placement="bottom-start" :hide-on-click="false" @visible-change="visibleChange">
        <div style="position: relative" @mouseenter="mouseHover = true" @mouseleave="mouseHover = false">
            <el-input
                :value="displayName"
                readonly
                size="small"
                class="ss-input"
                :class="{ 'is-reverse': dropdownVisible }"
                :placeholder="placeholder"
                suffix-icon="el-icon-arrow-up"
                @focus="handleFocus"
            />
            <i v-if="clearable && mouseHover && hasValue" class="el-icon-circle-close ss-clear" @click.stop="handleClear" />
        </div>
        <el-dropdown-menu slot="dropdown">
            <div v-if="filterable" class="ss-search">
                <el-input v-model="keyword" placeholder="搜索" size="mini" clearable @keydown.enter.stop />
            </div>
            <div v-if="multiple" style="padding: 6px 10px;">
                
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            </div>
            <scroll-list v-show="list.length" ref="scroll" :disabled="!hasMore" height="auto" min-height="50px" @load="loadData">
                <div
                    v-for="(item, i) in list"
                    :title="item.text"
                    :key="item.code"
                    class="ss-item"
                    :class="{ active: setSelect(item) }"
                    @click.stop="handleItemCheckedChange(item, i)"
                >
                    <div class="ellipsis">
                        <div v-if="multiple" style="display: inline-block;margin-right: 6px" @click.stop>
                            <el-checkbox :value="setSelect(item)" @change="e => handleItemCheckedChange(item, i, e)" />
                        </div>
                        <span>{{ item.text }}</span>
                    </div>
                    <div>
                        <i class="el-icon-check" />
                    </div>
                </div>
            </scroll-list>
            
            <el-dropdown-item v-if="!list.length" style="text-align: center;"><span style="color: #999;">暂无数据</span></el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
  
<script>
import ScrollList from "@/components/ScrollList.vue";

/**
 * SelectSearch 仿 Gitee搜索
 * @property {String | Number | Array} value    当前选项的值
 * @property {Boolean}			autoLoad		自定义数据加载，默认 false
 * @property {Function}			fetchApi		搜索接口
 * @property {String}           placeholder	    搜索框默认文案：请选择
 * @property {String}			activeColor		选中时的颜色，如设置parent的active-color将失效
 * @property {Boolean}			clearable	    是否可以清空，默认 true
 * @property {Boolean}			multiple	    是否可以多选，默认 false
 * @property {Number}	        multipleLimit	多选时用户最多可以选择的项目数，为 0 则不限制
 * @property {Boolean}	        filterable		是否可搜索
 * @property {Number}	        maxDisplayLimit	最多显示 label 数量，默认 3个
 * @property {Object}			options		    其他额外参数
 * @property {Array}            options.data    autoLoad 自定义数据加载为 true,需要手动传入数据
 * @property {String}           options.labelKey   显示的label 字段，默认 text
 * @property {String}           options.valueKey   渲染的唯一标识 value 字段，默认 code
 * @property {String}           options.searchKey  搜索框搜索的关键字，默认 search_str
 * @property {Array}            options.labelDataList 接口数据加载的首次加载需要显示的文案,需要手动传入数据
 * 
 * @example 有以下几种调用方式：
 * 1）远程接口 api调用方式：
 *  <select-search
 *      v-model="form.field"
 *      placeholder="请选择"
 *      :fetchApi="fetchList"
 *      :auto-load="false"
 *      multiple
 *      :max-display-limit="1"
 *      :options="{ data: enmuOptions, labelKey: 'store_pos_code', valueKey: 'wms_store_pos_id', searchKey: 'searchstr' }"
 *  />
 * 
 * 2）本地枚举类传入单选方式：
 *  <select-search
 *      v-model="form.field"
 *      placeholder="请选择"
 *      :multiple="false"
 *      auto-load
 *      :options="{ data: enmuOptions, labelKey: 'text', valueKey: 'code' }"
 *  />
 * 
 * 3）本地枚举类传入多选方式：
 *  <select-search
 *      v-model="form.field"
 *      placeholder="请选择"
 *      multiple
 *      auto-load
 *      :max-display-limit="2"
 *      :options="{ data: enmuOptions, labelKey: 'text', valueKey: 'code' }"
 *  />
 * ...
 */

export default {
    name: 'SelectSearch', // 仿 Gitee搜索
    components: { ScrollList },
    props: {
        value: {
            type: [String, Number, Array],
            default() {
                return []
            }
        },
        // 自定义数据加载
        autoLoad: {
            type: Boolean,
            default: true,
        },
        // 搜索接口
        fetchApi: {
            type: Function,
            default: () => {}
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        // 是否可以清空，默认 true
        clearable: {
            type: Boolean,
            default: true,
        },
        // 是否可以多选
        multiple: {
            type: Boolean,
            default: false,
        },
        // 多选时用户最多可以选择的项目数，为 0 则不限制
        multipleLimit: {
            type: Number,
            default: 0
        },
        // 是否可搜索
        filterable: {
            type: Boolean,
            default: true
        },
        // 最多显示 label 数量
        maxDisplayLimit: {
            type: Number,
            default: 3
        },
        // 其他参数
        options: {
            type: Object,
            default() {
                return {
                    data: [], // autoLoad 自定义数据加载为 true,需要手动传入数据
                    labelKey: 'text', // 显示的label 字段
                    valueKey: 'code', // 渲染的唯一标识 value 字段
                    searchKey: 'search_str', // 搜索框搜索的关键字
                    labelDataList: [], // 接口数据加载的首次加载需要显示的文案,需要手动传入数据
                }
            }
        },
    },
    data() {
        return {
            displayName: '',
            timer: null,
            labelMap: new Map(),
            keyword: '',
            list: [],
            mouseHover: false, // 鼠标移入移出
            initState: false, // 初始化完成状态
            hasChangeState: false, // 是否有数据变化状态
            checkAll: false, // 全选状态
            isIndeterminate: false, // 全选按钮半全角状态
            hasMore: true, // 是否有加载更多数据
            dropdownVisible: false, // 下拉的展开与收起
            pageParam: {
                pageIndex: 1,
                pageSize: 20
            },
        }
    },
    computed: {
        // 是否 value 有值
        hasValue() {
            const val = this.multiple ? this.value || [] : Array.isArray(this.value) ? undefined : this.value;
            return this.multiple ? !!val.length : (val != null && val !== '');
        },
    },
    watch: {
        value: {
            handler(val) {
                let arr = [];
                let str = '';
                val = this.multiple ? val || [] : Array.isArray(val) ? undefined : val;
                if (this.labelMap.size) {
                    if ((this.multiple && !(val || []).length) || (!this.multiple && (val == null || val === ''))) {
                        this.labelMap.clear();
                        this.checkAll = false;
                        this.isIndeterminate = false;
                    } else {
                        arr = [...this.labelMap.values()];
                    }
                } else {
                    if (Array.isArray(val)) {
                        arr = val.length ? this.list.filter(x => val.indexOf(x.code) > -1).map(x => x.text) : [];
                    } else {
                        arr = val ? [this.list.find(x => x.code === val)?.text].filter(Boolean) : [];
                    }
                }
                if (arr.length > this.maxDisplayLimit) {
                    str = arr.slice(0, this.maxDisplayLimit)?.join('、') + ` (+${arr.length - this.maxDisplayLimit})`;
                } else {
                    str = arr.join('、');
                }
                this.displayName = str;
    
            },
            immediate: true
        },
        keyword() {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                if (this.multiple) {
                    this.checkAll = false;
                    this.isIndeterminate = false;
                }
                if (this.autoLoad) {
                    this.list = (this.options.data || [])
                        .filter(x => x[this.options.labelKey].includes(this.keyword))
                        .map(x => ({ text: x[this.options.labelKey], code: x[this.options.valueKey], checked: false }));
                } else {
                    this.hasMore = true;
                    this.pageParam.pageIndex = 1;
                    this.getList();
                }
            }, 300)
        },
        'options.data': {
            handler(arr = []) {
                if (!this.autoLoad) return;
                this.hasMore = false;
                for (const item of this.options.labelDataList || []) {
                    this.labelMap.set(item[this.options.valueKey], item[this.options.labelKey]);
                }
                this.list = arr.map(x => ({ text: x[this.options.labelKey], code: x[this.options.valueKey], checked: false }));
            },
            immediate: true,
        }
    },
    beforeDestroy() {
        if (this.timer) clearTimeout(this.timer);
        this.timer = null;
        this.labelMap.clear();
        this.labelMap = null;
        this.list = [];
    },
    methods: {
        handleFocus() {
            if (this.initState) return;
            this.initState = true;
            if (!this.autoLoad) {
                if (!this.isPromise(this.fetchApi)) {
                    return console.error('SelectSearch.vue autoLoad 为 false时，需传入 fetchApi接口！');
                }
                this.getList();
            }
        },
        handleClear(e) {
            this.handleValueChange(this.multiple ? [] : undefined);
        },
        getList() {
            this.fetchApi({ ...this.pageParam, [this.options.searchKey]: this.keyword }).then(res => {
                if (res.data) {
                    let list = (res.data.list || []).map(x => ({ text: x[this.options.labelKey], code: x[this.options.valueKey], checked: false }));
                    this.list = this.pageParam.pageIndex === 1 ? list : [...this.list, ...list];
                    this.hasMore = this.pageParam.pageIndex * this.pageParam.pageSize < res.data.count;
                    this.$nextTick(() => {
                        this.checkAll = false;
                        this.isIndeterminate = false;
                        this.$refs.dropdownSearch.show();
                    })
                }
            })
        },
        loadData() {
            if (!this.hasMore) return;
            this.pageParam.pageIndex++;
            this.getList();
        },
        setSelect(item) {
            if (!this.multiple) return this.value === item.code;
            return this.value.indexOf(item.code) > -1;
        },
        handleCheckAllChange(val) {
            const valueArr = [...this.value];
            for (const item of this.list) {
                const index = valueArr.indexOf(item.code);
                if (val) {
                    // 选中的数量超过传入的最大数量时不再添加数据
                    if (this.multipleLimit && valueArr.length >= this.multipleLimit) break;
                    this.labelMap.set(item.code, item.text);
                    if (index === -1) valueArr.push(item.code);
                } else if (index > -1) {
                    valueArr.splice(index, 1);
                }
            }
            this.isIndeterminate = false;
            this.handleValueChange(valueArr);
        },
        handleItemCheckedChange(item, i) {
            if (this.multiple) {
                const arr = [...this.value];
                const index  = arr.indexOf(item.code);
                if (index === -1 && this.multipleLimit && valueArr.length >= this.multipleLimit) {
                    return this.$message.warning(`最多只能选择${this.multipleLimit}条数据！`)
                }
                index > -1 ? arr.splice(index, 1) : arr.push(item.code);
                index > -1 ? this.labelMap.delete(item.code) : this.labelMap.set(item.code, item.text);
                let checkedCount = arr.length;
                this.checkAll = checkedCount === this.list.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
                this.list[i].checked = !this.list[i].checked;
                this.handleValueChange(arr);
            } else {
                this.handleValueChange(item.code);
                this.hideDropdown();
            }
        },
        // 切换数据时，传递
        handleValueChange(data) {
            this.$emit('input', data);
            this.$emit('change', data);
        },
        // 处理 value 返回结果
        handleResult() {
            if (this.multiple) {
                return (this.value || []).filter(x => this.list.findIndex(y => y.code === x) > -1);
            } else {
                return this.value != null ? this.list.find(y => y.code === this.value) : {};
            }
        },
        visibleChange(e) {
            this.dropdownVisible = e;
            // 接口调用不能重置筛选，会出现重复调用的问题
            if (!e && this.autoLoad) this.keyword = '';
        },
        hideDropdown() {
            this.$refs.dropdownSearch.hide();
        },
        // 校验是不是 Promise对象
        isPromise(obj) {
            // 使用 instanceof操作符判断是不是 Promise对象
            const isInstancePromise = obj => obj instanceof Promise;

            // 检查对象是否有.then方法，所有的Promise对象都有一个.then方法，因此可以通过检查对象是否有这个方法来判断它是否是一个Promise。
            const hasThenPromise = obj => !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';

            // 结合使用 instanceof和检查 .then方法的方法，确保准确性
            return isInstancePromise(obj) || hasThenPromise(obj);
        }
    }
};
</script>
<style lang="scss" scoped>
    .ss {
        &-input {
            width: 100%;
            ::v-deep .el-input__inner {
                cursor: pointer;
            }
            ::v-deep .el-input__icon {
                transition: transform .3s, -webkit-transform .3s;
                transform: rotateZ(180deg);
            }
            
            &.is-reverse ::v-deep .el-input__icon {
                transform: rotateZ(0deg);
            }
        }
        &-clear {
            position: absolute;
            z-index: 2;
            right: 2px;
            font-size: 13px;
            top: 5px;
            padding: 6px;
            color: #C0C4CC;
            cursor: pointer;
            background-color: #fff;
        }
        &-search {
            padding: 0 10px;
            margin-bottom: 6px;
        }
        &-item {
            height: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 10px;
            cursor: pointer;

            > div {
                &:nth-child(1) {
                    max-width: 136px;
                    font-size: 14px;
                    color: #606262;
                }
                &:nth-child(2) {
                    display: none;
                    margin-left: 10px;
                    color: #3f54e2;
                    font-size: 18px;

                    ::v-deep .el-icon-check {
                        font-weight: 700;
                    }
                }
            }
            &.active {
                color:rgb(63, 84, 226);
                font-weight: 700;
                background-color: #f5f7fa;
                > div:nth-child(2) {
                    display: block;
                }
            }
        }
    }
</style>
  