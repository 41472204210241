<template>
    <div ref="scrollbarBox" class="scrollbarBox" :style="{ height, minHeight, maxHeight }" @scroll="handleScroll">
        <slot />
    </div>
</template>

<script>
let timer = null
export default {
    name: 'ScrollList', // 滚动列表
    props: {
        height: {
            type: String,
            default: '400px'
        },
        minHeight: {
            type: String,
            default: '40px'
        },
        maxHeight: {
            type: String,
            default: '400px'
        },
        // 用来判断是否滚动到底部加载更多
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tableData: [],
            guid: `loadmore_${Math.floor(Math.random() * 13000000)}`
        };
    },
    // 组件销毁清除监控
    destroyed() {
        this.dsetoryObserve()
    },
    methods: {
        dsetoryObserve() {
            // 这里运用毁灭生命周期 , 避免资源泄露和浪费
            if (timer) {
                clearTimeout(timer)
                timer = null
            }
        },
        handleScroll(e) {
            if (this.disabled) return; // 禁用状态下直接返回不处理
            const { scrollTop, scrollHeight, clientHeight } = e.target;
            // 检查是否滚动到底部
            if (scrollTop + clientHeight >= scrollHeight - 2) {
                if (timer) clearTimeout(timer)
                timer = setTimeout(() => {
                    this.$emit('load')
                }, 50)
            }
        },
        setScrollTop() {
            this.$refs.scrollbarBox.scrollTop = 0
            // document.querySelector(`#${this.guid}`).parentNode.scrollTop = 0
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.scrollbarBox {
  overflow-y: auto;
}
.loadmore {
    height: 20px;
    font-size: 12px;
    color: #666;
    padding: 6px;
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
}
</style>
